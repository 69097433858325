enum FieldNameEnum {
  HAS_CONTRACT_WITH_SOFTLINE = 'hasContractWithSoftline',
  COLLABORATION_PREFERENCE = 'collaborationPreference',
  PRODUCTS_OFFER = 'productsOffer',
  ACCEPTS_CONTRACT_CHANGES = 'acceptsContractChanges',
  EXCLUSIVE_CONTRACT_WITH_COMPANY = 'exclusiveContractWithCompany',
  DOCUMENTS = 'documents',
  INN = 'inn',
  COMPANY_NAME = 'companyName',
  WEBSITE = 'website',
  ANNUAL_TURNOVER = 'annualTurnover',
  EXPECTED_ANNUAL_TURNOVER = 'expectedAnnualTurnover',
  NUMBER_OF_EMPLOYEES = 'numberOfEmployees',
  WORK_DIRECTION = 'workDirection',
  HAS_COPYRIGHT_DOCUMENTS = 'hasCopyrightDocuments',
  PRODUCT_TYPE = 'productType',
  CUSTOMER_INTERACTION = 'customerInteraction',
  TARGET_AUDIENCE = 'targetAudience',
  INTERESTED_SERVICES = 'interestedServices',
  HAS_PARTNER_PROGRAM = 'hasPartnerProgram',
  MARKETING_BUDGET = 'marketingBudget',
}

export default FieldNameEnum;
