import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6db0896"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "help-widget" }
const _hoisted_2 = {
  type: "button",
  class: "help-widget__button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_CDropdownMenu = _resolveComponent("CDropdownMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CDropdownMenu, {
      "menu-item-list-full": _ctx.menuItemListFull,
      "menu-item-list-hover": _ctx.menuItemListHover,
      position: "top-left"
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", _hoisted_2, [
          _createVNode(_component_SIcon, {
            name: "question",
            color: "toolbar",
            size: "xl"
          })
        ])
      ]),
      _: 1
    }, 8, ["menu-item-list-full", "menu-item-list-hover"])
  ]))
}