<template>
  <LayoutWithoutToolbar
    width="md"
    :title="state.localisation.title"
  >
    <template
      #control-left
    >
      <SButton
        v-if="!state.isFormSent"
        :text="buttonCancel.text"
        :variant="buttonCancel.variant"
        @click="buttonCancel.handler"
      >
        <template
          #icon-before
        >
          <SIcon
            :color="buttonCancel.icon.color"
            :name="buttonCancel.icon.name"
          />
        </template>
      </SButton>
      <SButton
        v-else
        :text="buttonBack.text"
        :variant="buttonBack.variant"
        @click="buttonBack.handler"
      >
        <template
          #icon-before
        >
          <SIcon
            :color="buttonBack.icon.color"
            :name="buttonBack.icon.name"
          />
        </template>
      </SButton>
    </template>
    <template
      #control-right
    >
      <SButton
        v-if="!state.isFormSent"
        :size="buttonSave.size"
        :text="buttonSave.text"
        :variant="buttonSave.variant"
        @click="buttonSave.handler"
      >
      </SButton>
    </template>
    <template
      #content
    >
      <div
        class="portfolio-form-page"
      >
        <FormComponent
          v-if="!state.isFormSent"
          :form-config="state.formConfig"
          :form-data="state.formData"
          @string-field-blur="formFieldHandler.handleStringBlur"
          @text-input-list-add="formFieldHandler.handleTextInputListAddField"
          @text-input-list-delete="formFieldHandler.handleTextInputListDeleteField"
          @text-input-list-input="formFieldHandler.handleTextInputListInput"
          @file-upload-input="formFieldHandler.handleFileUploadInput"
          @delete-file="formFieldHandler.handleDeleteFile"
          @download-file="formFieldHandler.handleDownloadFile"
        >
          <template
            #input-list-item-after-item="{ status }"
          >
            <STextElement
              v-if="status && status.text"
              class="portfolio-form-page__implement-comment"
              color="danger"
              variant="small"
            >
              {{ status.text }}
            </STextElement>
          </template>
        </FormComponent>
        <CStatusWindow
          v-else
          :icon="statusWindow.icon"
          :title="statusWindow.title"
          :subtitle="statusWindow.subtitle"
        />
      </div>
    </template>
  </LayoutWithoutToolbar>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject, onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import {
  ColorEnum,
  IconNameEnum,
  SButtonVariantEnum, SizeEnum,
} from '@/shared/DesignSystem';
import {
  IValidatorPlugin,
} from '@/shared/Validator';

import AboutFormPage from '@/pages/Service/Portfolio/PortfolioFormPage/PortfolioFormPage';

import EPortfolioRouteName from '@/app/Enum/router/Service/EPortfolioRouteName';

import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

import FormComponent from '@/widgets/FormComponent/Component/FormComponent.vue';
import LayoutWithoutToolbar from '@/app/Component/Layout/LayoutWithoutToolbar/LayoutWithoutToolbar.vue';

export default defineComponent({
  components: {
    FormComponent,
    LayoutWithoutToolbar,
  },
  setup() {
    const serviceContainer = inject<IServiceContainer>('serviceContainer');
    if (!serviceContainer) {
      throw new Error('ServiceContainer not found. Check is plugin ServiceContainer/ServiceContainer.ts installed.');
    }

    const validatorPlugin = inject<IValidatorPlugin>('validatorPlugin');
    if (!validatorPlugin) {
      throw new Error('validatorPlugin service not found. Check is plugin validatorPlugin installed.');
    }

    const router = useRouter();
    const { t } = useI18n();

    const pageService = new AboutFormPage(serviceContainer);

    const state = pageService.initState(t);

    pageService.handlerService.init(validatorPlugin);

    const {
      formFieldHandler,
      formConfigHandler,
      sendDataHandler,
    } = pageService.handlerService;

    const buttonBack = computed(() => ({
      text: t('common.back'),
      variant: SButtonVariantEnum.TRANSPARENT,
      icon: {
        name: IconNameEnum.ARROW_LEFT_TAILED,
        color: ColorEnum.PRIMARY,
      },
      handler: () => router.push({ name: EPortfolioRouteName.OVERVIEW }),
    }));

    const buttonCancel = computed(() => ({
      text: t('common.cancel'),
      variant: SButtonVariantEnum.TRANSPARENT,
      icon: {
        name: IconNameEnum.CROSS,
        color: ColorEnum.PRIMARY,
      },
      handler: () => router.push({ name: EPortfolioRouteName.OVERVIEW }),
    }));

    const buttonSave = computed(() => ({
      size: SizeEnum.L,
      text: t('common.send'),
      variant: SButtonVariantEnum.FILLED,
      handler: () => {
        state.isSendButtonPressed = true;

        sendDataHandler.handle()
          .then(() => {
            state.isFormSent = true;
          })
          .catch((e) => console.log(e));
      },
    }));

    const statusWindow = {
      icon: {
        name: IconNameEnum.CHECK_CIRCLE,
        color: ColorEnum.SUCCESS,
        size: SizeEnum.XXL,
      },
      title: t('pages.portfolio.form.success.title'),
      subtitle: t('pages.portfolio.form.success.subtitle'),
    };

    onMounted(() => {
      state.formConfig = formConfigHandler.get(state);
    });

    return {
      state,
      buttonBack,
      buttonCancel,
      buttonSave,
      statusWindow,
      formFieldHandler,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.portfolio-form-page {
  &__implement-comment {
    margin-top: 8px;
  }
}
</style>
