import StringFieldBlurDataModelInterface
  from '@/widgets/FormComponent/lib/Model/StringFieldBlurDataModelInterface';
import KontragentioHandlerInterface
  from '@/pages/ScoringFormPage/lib/Contract/Handler/KontragentioHandlerInterface';
import StateModelInterface from '@/pages/ScoringFormPage/lib/Model/StateModelInterface';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import FieldNameEnum from '@/pages/ScoringFormPage/lib/Enum/FieldNameEnum';
import FormDataInterface from '@/pages/ScoringFormPage/lib/Model/FormDataInterface';

class KontragentioHandler implements KontragentioHandlerInterface {
  private state: StateModelInterface;

  private readonly serviceContainer: IServiceContainer;

  constructor(
    state: StateModelInterface,
    serviceContainer: IServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
  }

  handle = async (data: StringFieldBlurDataModelInterface): Promise<void> => {
    if (![10, 12].includes(data.value.length)) {
      return;
    }

    const { apiService } = this.serviceContainer;

    if (data.value.length === 10) {
      const response = await apiService.companyInfoApi.getCompanyInfo(data.value);

      const entity = response.data.search.entities[0] ?? {};

      this.updateFormData(data, entity.shortName ?? '');
    } else {
      const response = await apiService.companyInfoApi.getIndividualInfo(data.value);

      const entity = response.data.search.entities[0] ?? {};

      const fio: string[] = [];

      if (entity.lastName) {
        fio.push(entity.lastName);
      }
      if (entity.firstName) {
        fio.push(entity.firstName);
      }
      if (entity.middleName) {
        fio.push(entity.middleName);
      }

      if (fio.length > 0) {
        this.updateFormData(data, fio.join(' '));
      }
    }
  };

  private updateFormData = (data: StringFieldBlurDataModelInterface, name: string) => {
    const { utilsService } = this.serviceContainer;

    const formData = utilsService.data.cloneImmutable<FormDataInterface>(
      data.formData as unknown as FormDataInterface,
    );

    formData[FieldNameEnum.COMPANY_NAME] = name;

    this.state.formData = formData;
  }
}

export default KontragentioHandler;
