<template>
  <div
    :class="classes"
  >
    <LayoutWithoutToolbarControl
      v-if="haveControl"
      class="layout-without-toolbar__control"
    >
      <template
        v-if="isControlLeftSlot"
        #control-left
      >
        <slot
          name="control-left"
        />
      </template>
      <template
        v-if="isControlRightSlot"
        #control-right
      >
        <slot
          name="control-right"
        />
      </template>
    </LayoutWithoutToolbarControl>

    <LayoutWithoutToolbarContainer
      :title="title"
      :width="width"
      class="layout-without-toolbar__container"
    >
      <template
        #content
      >
        <slot
          name="content"
        />
      </template>
    </LayoutWithoutToolbarContainer>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import ELayoutWidth from '@/app/Component/Layout/LayoutWithoutToolbar/Enum/ELayoutWidth';
import LayoutWithoutToolbarControl
  from '@/app/Component/Layout/LayoutWithoutToolbar/Module/LayoutWithoutToolbarControl.vue';

import LayoutWithoutToolbarContainer
  from '@/app/Component/Layout/LayoutWithoutToolbar/Module/LayoutWithoutToolbarContainer.vue';

export default defineComponent({
  components: {
    LayoutWithoutToolbarContainer,
    LayoutWithoutToolbarControl,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String as PropType<ELayoutWidth>,
      default: ELayoutWidth.SM,
    },
  },
  setup(props, { slots }) {
    const isControlLeftSlot = computed(() => !!slots['control-left']);
    const isControlRightSlot = computed(() => !!slots['control-right']);
    const haveControl = computed(() => isControlLeftSlot.value || isControlRightSlot.value);

    const classes = computed(() => [
      'layout-without-toolbar',
      `layout-without-toolbar_width_${props.width}`,
      !haveControl.value ? 'layout-without-toolbar_no-control' : null,
    ]);
    const classesContent = computed(() => [
      'layout-without-toolbar__content',
      `layout-without-toolbar__content_width_${props.width}`,
    ]);

    return {
      classes,
      classesContent,
      haveControl,
      isControlLeftSlot,
      isControlRightSlot,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.layout-without-toolbar {
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid #ffffff;
    border-radius: 5px;
    background: #aaaaaa;

    &:hover {
      background: #999999;
    }
  }

  &_no-control &__container {
    padding-top: var(--layout-without-toolbar-container-xxl-padding-top);
  }
}
</style>
