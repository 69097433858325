import IUtilsService from '@/app/Service/UtilsService/Contract/IUtilsService';
import OrConditionModelInterface
  from '@/widgets/FormComponent/lib/Model/Condition/OrConditionModelInterface';
import NotEmptyConditionModelInterface
  from '@/widgets/FormComponent/lib/Model/Condition/NotEmptyConditionModelInterface';
import AndConditionModelInterface
  from '@/widgets/FormComponent/lib/Model/Condition/AndConditionModelInterface';
import EqualsConditionModelInterface
  from '@/widgets/FormComponent/lib/Model/Condition/EqualsConditionModelInterface';
import NotEqualsConditionModelInterface
  from '@/widgets/FormComponent/lib/Model/Condition/NotEqualsConditionModelInterface';
import ConditionTypeEnum from './Enum/ConditionTypeEnum';
import ConditionModelType from './Model/Condition/ConditionModelType';
import ICheckConditionTool from './Contract/ICheckConditionTool';

class CheckConditionTool implements ICheckConditionTool {
  private utilsService: IUtilsService;

  constructor(utilsService: IUtilsService) {
    this.utilsService = utilsService;
  }

  private isOrCondition(
    condition: ConditionModelType,
  ): condition is OrConditionModelInterface {
    return condition.type === ConditionTypeEnum.OR;
  }

  private isAndCondition(
    condition: ConditionModelType,
  ): condition is AndConditionModelInterface {
    return condition.type === ConditionTypeEnum.AND;
  }

  private isEqualsCondition(
    condition: ConditionModelType,
  ): condition is EqualsConditionModelInterface {
    return condition.type === ConditionTypeEnum.EQUALS;
  }

  private isNotEqualsCondition(
    condition: ConditionModelType,
  ): condition is NotEqualsConditionModelInterface {
    return condition.type === ConditionTypeEnum.NOT_EQUALS;
  }

  private isNotEmptyCondition(
    condition: ConditionModelType,
  ): condition is NotEmptyConditionModelInterface {
    return condition.type === ConditionTypeEnum.NOT_EMPTY;
  }

  resolveAndCondition = (conditionsList: ConditionModelType[], data: object): boolean => {
    let initial = true;

    conditionsList.forEach((condition) => {
      initial = initial && this.resolveCondition(condition, data);
    });

    return initial;
  }

  resolveCondition = (condition: ConditionModelType, data: object): boolean => {
    let resolved = true;

    if (!Object.values(ConditionTypeEnum).includes(condition.type)) {
      throw new Error('Invalid condition type.');
    }

    if (this.isOrCondition(condition)) {
      resolved = this.resolveOrCondition(condition.conditionsList ?? [], data);
    }

    if (this.isAndCondition(condition)) {
      resolved = this.resolveAndCondition(condition.conditionsList ?? [], data);
    }

    if (this.isEqualsCondition(condition)) {
      resolved = this.resolveEqualityCondition(condition.field, data, condition.value);
    }

    if (this.isNotEqualsCondition(condition)) {
      resolved = !this.resolveEqualityCondition(condition.field, data, condition.value);
    }

    if (this.isNotEmptyCondition(condition)) {
      resolved = this.resolveNotEmptyCondition(condition.field, data);
    }

    return resolved;
  };

  resolveEqualityCondition = (field: string, data: object, value: unknown): boolean => {
    const dataValue = this.utilsService.object.readProp(data, field);

    return dataValue === value;
  };

  resolveNotEmptyCondition(field: string, data: object): boolean {
    const dataValue = this.utilsService.object.readProp(data, field);

    return this.utilsService.data.isEmpty(dataValue);
  }

  resolveOrCondition(conditionsList: ConditionModelType[], data: object): boolean {
    let initial = false;

    conditionsList.forEach(
      (condition) => {
        initial = initial || this.resolveCondition(condition, data);
      },
    );

    return initial;
  }
}

export default CheckConditionTool;
