import {
  CChipsGroupModeEnum,
  ColorEnum,
  FileErrorMessagesType,
  FileErrorTypeEnum,
  FileExtensionsEnum,
  FileMimeTypesEnum,
  getFileUploadOptions,
  IconNameEnum,
  SButtonVariantEnum,
  SChipsItemInterface,
  SizeEnum,
  SMaskedInputMaskInterface,
} from '@/shared/DesignSystem';

import {
  CChipsGroupFieldConfigInterface,
  ConditionTypeEnum,
  CustomInfoBlockConfigInterface,
  DateFieldConfigInterface,
  FieldTypeEnum,
  FileUploadFieldConfigInterface,
  FormBlockType,
  FormBlockTypeEnum,
  MaskedInputFieldConfigInterface,
  TextAreaFieldConfigInterface,
  TextInputFieldConfigInterface,
  TextInputListFieldConfigInterface,
} from '@/widgets/FormComponent';

import EActiveType from '@/pages/Service/Promo/PromoFormPage/Enum/EActiveType';
import EApplication from '@/pages/Service/Promo/PromoFormPage/Enum/EApplication';
import EBuyersParticipantsPromotion from '@/pages/Service/Promo/PromoFormPage/Enum/EBuyersParticipantsPromotion';
import ECategoriesParticipants from '@/pages/Service/Promo/PromoFormPage/Enum/ECategoriesParticipants';
import ECouponValidity from '@/pages/Service/Promo/PromoFormPage/Enum/ECouponValidity';
import EDiscountReflected from '@/pages/Service/Promo/PromoFormPage/Enum/EDiscountReflected';
import EFieldName from '@/pages/Service/Promo/PromoFormPage/Enum/EFieldName';
import EParticipantsPromotion from '@/pages/Service/Promo/PromoFormPage/Enum/EParticipantsPromotion';
import EPromotionType from '@/pages/Service/Promo/PromoFormPage/Enum/EPromotionType';
import EUseSticker from '@/pages/Service/Promo/PromoFormPage/Enum/EUseSticker';
import EValidationMessageTokens from '@/app/Enum/EValidationMessageTokens';
import EVariantAmountDiscount from '@/pages/Service/Promo/PromoFormPage/Enum/EVariantAmountDiscount';
import EVariantArticleKit from '@/pages/Service/Promo/PromoFormPage/Enum/EVariantArticleKit';

import IFormConfigHandler from '@/pages/Service/Promo/PromoFormPage/Contract/Handler/IFormConfigHandler';
import IState from '@/pages/Service/Promo/PromoFormPage/Model/IState';

class FormConfigHandler implements IFormConfigHandler {
  get = (
    state: IState,
  ): Array<FormBlockType> => {
    const activeType: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.ACTIVE_TYPE,
      config: {
        label: state.t('pages.promo.form.fields.active_type.label'),
        mode: CChipsGroupModeEnum.SINGLE,
        list: this.getList(state, EFieldName.ACTIVE_TYPE),
      },
      status: state.validationStatusRegistry[EFieldName.ACTIVE_TYPE],
    };

    const promotionType: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.PROMOTION_TYPE,
      config: {
        label: state.t('pages.promo.form.fields.promotion_type.label'),
        mode: CChipsGroupModeEnum.SINGLE,
        list: this.getList(state, EFieldName.PROMOTION_TYPE),
      },
      status: state.validationStatusRegistry[EFieldName.PROMOTION_TYPE],
      conditionList: [
        {
          type: ConditionTypeEnum.EQUALS,
          value: true,
          field: [
            EFieldName.ACTIVE_TYPE,
            `(find:id=${EActiveType.PROMOTION})`,
            'checked',
          ].join('.'),
        },
      ],
    };

    const customInfoBlock: CustomInfoBlockConfigInterface = {
      type: FieldTypeEnum.CUSTOM_INFO_BLOCK,
      fieldName: EFieldName.PROMOTION_INFO,
    };

    const promotionName: TextInputFieldConfigInterface = {
      type: FieldTypeEnum.TEXT_INPUT,
      fieldName: EFieldName.PROMOTION_NAME,
      config: {
        placeholder: state.t('pages.promo.form.fields.promotion_name.placeholder'),
        label: state.t('pages.promo.form.fields.promotion_name.label'),
        description: state.t('pages.promo.form.fields.promotion_name.description'),
      },
      status: state.validationStatusRegistry[EFieldName.PROMOTION_NAME],
      conditionList: [
        {
          type: ConditionTypeEnum.EQUALS,
          value: true,
          field: [
            EFieldName.ACTIVE_TYPE,
            `(find:id=${EActiveType.PROMOTION})`,
            'checked',
          ].join('.'),
        },
      ],
    };

    const descriptionForCustomers: TextAreaFieldConfigInterface = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.DESCRIPTION_FOR_CUSTOMERS,
      config: {
        placeholder: state.t('pages.promo.form.fields.description_for_customers.placeholder'),
        label: state.t('pages.promo.form.fields.description_for_customers.label'),
        description: state.t('pages.promo.form.fields.description_for_customers.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.DESCRIPTION_FOR_CUSTOMERS],
    };

    const activationDate: DateFieldConfigInterface = {
      type: FieldTypeEnum.DATE_FIELD,
      fieldName: EFieldName.ACTIVATION_DATE,
      config: {
        label: state.t('pages.promo.form.fields.activation_date.label'),
        description: state.t('pages.promo.form.fields.activation_date.description'),
        mode: 'dateTime',
        minDate: new Date(),
        maxDate: new Date(new Date().getFullYear() + 1, 11, 31, 23, 59),
        buttons: {
          ok: state.t('common.apply'),
          cancel: state.t('common.cancel'),
        },
        baseTimeZone: {
          name: state.t('common.timezone.short_name.msk'),
          offset: 3,
        },
      },
      status: state.validationStatusRegistry[EFieldName.ACTIVATION_DATE],
    };

    const expirationDate: DateFieldConfigInterface = {
      type: FieldTypeEnum.DATE_FIELD,
      fieldName: EFieldName.EXPIRATION_DATE,
      config: {
        label: state.t('pages.promo.form.fields.expiration_date.label'),
        description: state.t('pages.promo.form.fields.expiration_date.description'),
        mode: 'dateTime',
        minDate: new Date(),
        maxDate: new Date(new Date().getFullYear() + 1, 11, 31, 23, 59),
        buttons: {
          ok: state.t('common.apply'),
          cancel: state.t('common.cancel'),
        },
        baseTimeZone: {
          name: state.t('common.timezone.short_name.msk'),
          offset: 3,
        },
      },
      status: state.validationStatusRegistry[EFieldName.EXPIRATION_DATE],
    };

    const variantAmountDiscount: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.VARIANT_AMOUNT_DISCOUNT,
      config: {
        label: state.t('pages.promo.form.fields.variant_amount_discount.label'),
        mode: CChipsGroupModeEnum.SINGLE,
        list: this.getList(state, EFieldName.VARIANT_AMOUNT_DISCOUNT),
      },
      status: state.validationStatusRegistry[EFieldName.VARIANT_AMOUNT_DISCOUNT],
      conditionList: [
        {
          type: ConditionTypeEnum.AND,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.NOT_EQUALS,
              value: true,
              field: [
                EFieldName.PROMOTION_TYPE,
                `(find:id=${EPromotionType.TEXT_PROMOTION})`,
                'checked',
              ].join('.'),
            },
          ],
        },
      ],
    };

    const application: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.APPLICATION,
      config: {
        label: state.t('pages.promo.form.fields.application.label'),
        mode: CChipsGroupModeEnum.SINGLE,
        list: this.getList(state, EFieldName.APPLICATION),
      },
      status: state.validationStatusRegistry[EFieldName.APPLICATION],
      conditionList: [
        {
          type: ConditionTypeEnum.AND,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.NOT_EQUALS,
              value: true,
              field: [
                EFieldName.PROMOTION_TYPE,
                `(find:id=${EPromotionType.TEXT_PROMOTION})`,
                'checked',
              ].join('.'),
            },
          ],
        },
      ],
    };

    const amountDiscount: MaskedInputFieldConfigInterface = {
      type: FieldTypeEnum.MASKED_INPUT,
      fieldName: EFieldName.AMOUNT_DISCOUNT,
      config: {
        label: state.t('pages.promo.form.fields.amount_discount.label'),
        description: state.t('pages.promo.form.fields.amount_discount.description'),
        mask: this.getDiscountMaskOption(state),
      },
      status: state.validationStatusRegistry[EFieldName.AMOUNT_DISCOUNT],
      conditionList: [
        {
          type: ConditionTypeEnum.AND,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.NOT_EQUALS,
              value: true,
              field: [
                EFieldName.PROMOTION_TYPE,
                `(find:id=${EPromotionType.TEXT_PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.APPLICATION,
                `(find:id=${EApplication.ALL_PRODUCTS})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.OR,
              conditionsList: [
                {
                  type: ConditionTypeEnum.EQUALS,
                  value: true,
                  field: [
                    EFieldName.VARIANT_AMOUNT_DISCOUNT,
                    `(find:id=${EVariantAmountDiscount.IN_RUB})`,
                    'checked',
                  ].join('.'),
                },
                {
                  type: ConditionTypeEnum.EQUALS,
                  value: true,
                  field: [
                    EFieldName.VARIANT_AMOUNT_DISCOUNT,
                    `(find:id=${EVariantAmountDiscount.IN_PERCENT})`,
                    'checked',
                  ].join('.'),
                },
              ],
            },
          ],
        },
      ],
    };

    const products: TextInputListFieldConfigInterface = {
      type: FieldTypeEnum.TEXT_INPUT_LIST,
      fieldName: EFieldName.PRODUCTS,
      config: {
        placeholder: state.t('pages.promo.form.fields.products.placeholder'),
        label: state.t('pages.promo.form.fields.products.label'),
        description: state.t('pages.promo.form.fields.products.description'),
        addButtonText: state.t('pages.promo.form.fields.products.add_button'),
        minItems: 1,
        statuses: state.productsValidationStatusRegistry,
      },
      status: state.validationStatusRegistry[EFieldName.PRODUCTS],
    };

    const participantsPromotion: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.PARTICIPANTS_PROMOTION,
      config: {
        label: state.t('pages.promo.form.fields.participants_promotion.label'),
        mode: CChipsGroupModeEnum.SINGLE,
        list: this.getList(state, EFieldName.PARTICIPANTS_PROMOTION),
      },
      status: state.validationStatusRegistry[EFieldName.PARTICIPANTS_PROMOTION],
      conditionList: [
        {
          type: ConditionTypeEnum.EQUALS,
          value: true,
          field: [
            EFieldName.ACTIVE_TYPE,
            `(find:id=${EActiveType.PROMOTION})`,
            'checked',
          ].join('.'),
        },
      ],
    };

    const buyersParticipantsPromotion: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.BUYERS_PARTICIPANTS_PROMOTION,
      config: {
        label: state.t('pages.promo.form.fields.buyers_participants_promotion.label'),
        mode: CChipsGroupModeEnum.SINGLE,
        list: this.getList(state, EFieldName.BUYERS_PARTICIPANTS_PROMOTION),
      },
      status: state.validationStatusRegistry[EFieldName.BUYERS_PARTICIPANTS_PROMOTION],
      conditionList: [
        {
          type: ConditionTypeEnum.EQUALS,
          value: true,
          field: [
            EFieldName.ACTIVE_TYPE,
            `(find:id=${EActiveType.PROMOTION})`,
            'checked',
          ].join('.'),
        },
      ],
    };

    const categoriesParticipants: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.CATEGORIES_PARTICIPANTS,
      config: {
        label: state.t('pages.promo.form.fields.categories_participants.label'),
        mode: CChipsGroupModeEnum.MULTIPLE,
        list: this.getList(state, EFieldName.CATEGORIES_PARTICIPANTS),
      },
      status: state.validationStatusRegistry[EFieldName.CATEGORIES_PARTICIPANTS],
      conditionList: [
        {
          type: ConditionTypeEnum.EQUALS,
          value: true,
          field: [
            EFieldName.ACTIVE_TYPE,
            `(find:id=${EActiveType.PROMOTION})`,
            'checked',
          ].join('.'),
        },
      ],
    };

    const whichCategory: TextAreaFieldConfigInterface = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.WHICH_CATEGORY,
      config: {
        placeholder: state.t('pages.promo.form.fields.which_category.placeholder'),
        label: state.t('pages.promo.form.fields.which_category.label'),
        description: state.t('pages.promo.form.fields.which_category.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.WHICH_CATEGORY],
      conditionList: [
        {
          type: ConditionTypeEnum.AND,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.NOT_EQUALS,
              value: true,
              field: [
                EFieldName.CATEGORIES_PARTICIPANTS,
                `(find:id=${EPromotionType.TEXT_PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.CATEGORIES_PARTICIPANTS,
                `(find:id=${ECategoriesParticipants.OTHER})`,
                'checked',
              ].join('.'),
            },
          ],
        },
      ],
    };

    const discountReflected: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.DISCOUNT_REFLECTED,
      config: {
        label: state.t('pages.promo.form.fields.discount_reflected.label'),
        mode: CChipsGroupModeEnum.SINGLE,
        list: this.getList(state, EFieldName.DISCOUNT_REFLECTED),
      },
      status: state.validationStatusRegistry[EFieldName.DISCOUNT_REFLECTED],
      conditionList: [
        {
          type: ConditionTypeEnum.AND,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.NOT_EQUALS,
              value: true,
              field: [
                EFieldName.PROMOTION_TYPE,
                `(find:id=${EPromotionType.TEXT_PROMOTION})`,
                'checked',
              ].join('.'),
            },
          ],
        },
      ],
    };

    const banner: FileUploadFieldConfigInterface = {
      type: FieldTypeEnum.FILE_UPLOAD,
      fieldName: EFieldName.BANNER,
      config: {
        label: state.t('pages.promo.form.fields.banner.label'),
        options: this.getDocumentOptions(state),
      },
      status: state.validationStatusRegistry[EFieldName.BANNER],
      conditionList: [
        {
          type: ConditionTypeEnum.EQUALS,
          value: true,
          field: [
            EFieldName.ACTIVE_TYPE,
            `(find:id=${EActiveType.PROMOTION})`,
            'checked',
          ].join('.'),
        },
      ],
    };

    const useSticker: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.USE_STICKER,
      config: {
        label: state.t('pages.promo.form.fields.use_sticker.label'),
        description: state.t('pages.promo.form.fields.use_sticker.descritpion'),
        mode: CChipsGroupModeEnum.SINGLE,
        list: this.getList(state, EFieldName.USE_STICKER),
      },
      status: state.validationStatusRegistry[EFieldName.USE_STICKER],
      conditionList: [
        {
          type: ConditionTypeEnum.EQUALS,
          value: true,
          field: [
            EFieldName.ACTIVE_TYPE,
            `(find:id=${EActiveType.PROMOTION})`,
            'checked',
          ].join('.'),
        },
      ],
    };

    const textSticker: TextInputFieldConfigInterface = {
      type: FieldTypeEnum.TEXT_INPUT,
      fieldName: EFieldName.TEXT_STICKER,
      config: {
        placeholder: state.t('pages.promo.form.fields.text_sticker.placeholder'),
        label: state.t('pages.promo.form.fields.text_sticker.label'),
        description: state.t('pages.promo.form.fields.text_sticker.description'),
      },
      status: state.validationStatusRegistry[EFieldName.TEXT_STICKER],
      conditionList: [
        {
          type: ConditionTypeEnum.OR,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.STICKER})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.AND,
              conditionsList: [
                {
                  type: ConditionTypeEnum.EQUALS,
                  value: true,
                  field: [
                    EFieldName.ACTIVE_TYPE,
                    `(find:id=${EActiveType.PROMOTION})`,
                    'checked',
                  ].join('.'),
                },
                {
                  type: ConditionTypeEnum.EQUALS,
                  value: true,
                  field: [
                    EFieldName.USE_STICKER,
                    `(find:id=${EUseSticker.USE})`,
                    'checked',
                  ].join('.'),
                },
              ],
            },
          ],
        },
      ],
    };

    const descriptionBuyers: TextAreaFieldConfigInterface = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.DESCRIPTION_BUYERS,
      config: {
        placeholder: state.t('pages.promo.form.fields.description_buyers.placeholder'),
        label: state.t('pages.promo.form.fields.description_buyers.label'),
        description: state.t('pages.promo.form.fields.description_buyers.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.DESCRIPTION_BUYERS],
      conditionList: [
        {
          type: ConditionTypeEnum.AND,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.USE_STICKER,
                `(find:id=${EUseSticker.USE})`,
                'checked',
              ].join('.'),
            },
          ],
        },
      ],
    };

    const couponValidity: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.COUPON_VALIDITY,
      config: {
        label: state.t('pages.promo.form.fields.coupon_validity.label'),
        mode: CChipsGroupModeEnum.SINGLE,
        list: this.getList(state, EFieldName.COUPON_VALIDITY),
      },
      status: state.validationStatusRegistry[EFieldName.COUPON_VALIDITY],
      conditionList: [
        {
          type: ConditionTypeEnum.AND,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.PROMOTION_TYPE,
                `(find:id=${EPromotionType.PROMO_CODES})`,
                'checked',
              ].join('.'),
            },
          ],
        },
      ],
    };

    const mechanicsPromotion: TextAreaFieldConfigInterface = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.MECHANICS_PROMOTION,
      config: {
        placeholder: state.t('pages.promo.form.fields.mechanics_promotion.placeholder'),
        label: state.t('pages.promo.form.fields.mechanics_promotion.label'),
        description: state.t('pages.promo.form.fields.mechanics_promotion.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.MECHANICS_PROMOTION],
      conditionList: [
        {
          type: ConditionTypeEnum.AND,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.PROMOTION_TYPE,
                `(find:id=${EPromotionType.TEXT_PROMOTION})`,
                'checked',
              ].join('.'),
            },
          ],
        },
      ],
    };

    const variantArticleKit: CChipsGroupFieldConfigInterface = {
      type: FieldTypeEnum.CHIPS_GROUP,
      fieldName: EFieldName.VARIANT_ARTICLE_KIT,
      config: {
        label: state.t('pages.promo.form.fields.variant_article_kit.label'),
        mode: CChipsGroupModeEnum.SINGLE,
        list: this.getList(state, EFieldName.VARIANT_ARTICLE_KIT),
      },
      status: state.validationStatusRegistry[EFieldName.VARIANT_ARTICLE_KIT],
      conditionList: [
        {
          type: ConditionTypeEnum.AND,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.PROMOTION_TYPE,
                `(find:id=${EPromotionType.KIT})`,
                'checked',
              ].join('.'),
            },
          ],
        },
      ],
    };

    const articleKit: TextInputFieldConfigInterface = {
      type: FieldTypeEnum.TEXT_INPUT,
      fieldName: EFieldName.ARTICLE_KIT,
      config: {
        placeholder: state.t('pages.promo.form.fields.article_kit.placeholder'),
        label: state.t('pages.promo.form.fields.article_kit.label'),
        description: state.t('pages.promo.form.fields.article_kit.description'),
      },
      status: state.validationStatusRegistry[EFieldName.ARTICLE_KIT],
      conditionList: [
        {
          type: ConditionTypeEnum.AND,
          conditionsList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.ACTIVE_TYPE,
                `(find:id=${EActiveType.PROMOTION})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.PROMOTION_TYPE,
                `(find:id=${EPromotionType.KIT})`,
                'checked',
              ].join('.'),
            },
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.VARIANT_ARTICLE_KIT,
                `(find:id=${EVariantArticleKit.SEPARATE_KIT})`,
                'checked',
              ].join('.'),
            },
          ],
        },
      ],
    };

    return [
      {
        type: FormBlockTypeEnum.BLOCK,
        title: '',
        subTitle: '',
        fields: [
          activeType,
          promotionType,
          customInfoBlock,
          promotionName,
          descriptionForCustomers,
          activationDate,
          expirationDate,
          variantAmountDiscount,
          application,
          amountDiscount,
          products,
          participantsPromotion,
          buyersParticipantsPromotion,
          categoriesParticipants,
          whichCategory,
          discountReflected,
          banner,
          useSticker,
          textSticker,
          descriptionBuyers,
          couponValidity,
          mechanicsPromotion,
          variantArticleKit,
          articleKit,
        ],
      },
    ];
  };

  private getList(
    state: IState,
    field: EFieldName.ACTIVE_TYPE
      | EFieldName.PROMOTION_TYPE
      | EFieldName.VARIANT_AMOUNT_DISCOUNT
      | EFieldName.APPLICATION
      | EFieldName.PARTICIPANTS_PROMOTION
      | EFieldName.BUYERS_PARTICIPANTS_PROMOTION
      | EFieldName.CATEGORIES_PARTICIPANTS
      | EFieldName.DISCOUNT_REFLECTED
      | EFieldName.USE_STICKER
      | EFieldName.COUPON_VALIDITY
      | EFieldName.VARIANT_ARTICLE_KIT,
  ): Array<SChipsItemInterface> {
    const listMap: {
      [key in EFieldName.ACTIVE_TYPE
        | EFieldName.PROMOTION_TYPE
        | EFieldName.VARIANT_AMOUNT_DISCOUNT
        | EFieldName.APPLICATION
        | EFieldName.PARTICIPANTS_PROMOTION
        | EFieldName.BUYERS_PARTICIPANTS_PROMOTION
        | EFieldName.CATEGORIES_PARTICIPANTS
        | EFieldName.DISCOUNT_REFLECTED
        | EFieldName.USE_STICKER
        | EFieldName.COUPON_VALIDITY
        | EFieldName.VARIANT_ARTICLE_KIT
      ]: Array<SChipsItemInterface> } = {
        [EFieldName.ACTIVE_TYPE]: [
          {
            id: EActiveType.PROMOTION,
            title: state.t('pages.promo.form.fields.active_type.values.promotion'),
            checked: true,
          },
          {
            id: EActiveType.STICKER,
            title: state.t('pages.promo.form.fields.active_type.values.sticker'),
            checked: false,
          },
        ],
        [EFieldName.PROMOTION_TYPE]: [
          {
            id: EPromotionType.DISCOUNT,
            title: state.t('pages.promo.form.fields.promotion_type.values.discount'),
            checked: true,
          },
          {
            id: EPromotionType.PROMO_CODES,
            title: state.t('pages.promo.form.fields.promotion_type.values.promo_codes'),
            checked: false,
          },
          {
            id: EPromotionType.TEXT_PROMOTION,
            title: state.t('pages.promo.form.fields.promotion_type.values.text_promotion'),
            checked: false,
          },
          {
            id: EPromotionType.KIT,
            title: state.t('pages.promo.form.fields.promotion_type.values.kit'),
            checked: false,
          },
        ],
        [EFieldName.VARIANT_AMOUNT_DISCOUNT]: [
          {
            id: EVariantAmountDiscount.IN_RUB,
            title: state.t('pages.promo.form.fields.variant_amount_discount.values.in_rub'),
            checked: false,
          },
          {
            id: EVariantAmountDiscount.IN_PERCENT,
            title: state.t('pages.promo.form.fields.variant_amount_discount.values.in_percent'),
            checked: false,
          },
        ],
        [EFieldName.APPLICATION]: [
          {
            id: EApplication.ALL_PRODUCTS,
            title: state.t('pages.promo.form.fields.application.values.all_products'),
            checked: false,
          },
          {
            id: EApplication.EVERY_PRODUCTS,
            title: state.t('pages.promo.form.fields.application.values.every_products'),
            checked: false,
          },
        ],
        [EFieldName.PARTICIPANTS_PROMOTION]: [
          {
            id: EParticipantsPromotion.ONLY_CUSTOMERS,
            title: state.t('pages.promo.form.fields.participants_promotion.values.only_customers'),
            checked: false,
          },
          {
            id: EParticipantsPromotion.ALL_CUSTOMERS,
            title: state.t('pages.promo.form.fields.participants_promotion.values.all_customers'),
            checked: false,
          },
        ],
        [EFieldName.BUYERS_PARTICIPANTS_PROMOTION]: [
          {
            id: EBuyersParticipantsPromotion.ONLY_NEW_BUYERS,
            title: state.t('pages.promo.form.fields.buyers_participants_promotion.values.only_new_buyers'),
            checked: false,
          },
          {
            id: EBuyersParticipantsPromotion.ONLY_CURREN_BUYERS,
            title: state.t('pages.promo.form.fields.buyers_participants_promotion.values.only_curren_buyers'),
            checked: false,
          },
          {
            id: EBuyersParticipantsPromotion.ALL_BUYERS,
            title: state.t('pages.promo.form.fields.buyers_participants_promotion.values.all_buyers'),
            checked: false,
          },
        ],
        [EFieldName.CATEGORIES_PARTICIPANTS]: [
          {
            id: ECategoriesParticipants.ALL_CATEGORIES,
            title: state.t('pages.promo.form.fields.categories_participants.values.all_categories'),
            checked: false,
          },
          {
            id: ECategoriesParticipants.GOVERNMENT_INSTITUTIONS,
            title: state.t('pages.promo.form.fields.categories_participants.values.government_institutions'),
            checked: false,
          },
          {
            id: ECategoriesParticipants.EDUCATIONAL_INSTITUTIONS,
            title: state.t('pages.promo.form.fields.categories_participants.values.educational_institutions'),
            checked: false,
          },
          {
            id: ECategoriesParticipants.COMMERCIAL_INSTITUTIONS,
            title: state.t('pages.promo.form.fields.categories_participants.values.commercial_institutions'),
            checked: false,
          },
          {
            id: ECategoriesParticipants.MEDICAL_INSTITUTIONS,
            title: state.t('pages.promo.form.fields.categories_participants.values.medical_institutions'),
            checked: false,
          },
          {
            id: ECategoriesParticipants.OTHER,
            title: state.t('pages.promo.form.fields.categories_participants.values.other'),
            checked: false,
          },
        ],
        [EFieldName.DISCOUNT_REFLECTED]: [
          {
            id: EDiscountReflected.INCLUDE,
            title: state.t('pages.promo.form.fields.discount_reflected.values.include'),
            checked: false,
          },
          {
            id: EDiscountReflected.EXCLUDE,
            title: state.t('pages.promo.form.fields.discount_reflected.values.exclude'),
            checked: false,
          },
        ],
        [EFieldName.USE_STICKER]: [
          {
            id: EUseSticker.USE,
            title: state.t('pages.promo.form.fields.use_sticker.values.use'),
            checked: false,
          },
          {
            id: EUseSticker.NOT_USE,
            title: state.t('pages.promo.form.fields.use_sticker.values.not_use'),
            checked: false,
          },
        ],
        [EFieldName.COUPON_VALIDITY]: [
          {
            id: ECouponValidity.ONE_TIME,
            title: state.t('pages.promo.form.fields.coupon_validity.values.one_time'),
            checked: false,
          },
          {
            id: ECouponValidity.REUSABLE,
            title: state.t('pages.promo.form.fields.coupon_validity.values.reusable'),
            checked: false,
          },
        ],
        [EFieldName.VARIANT_ARTICLE_KIT]: [
          {
            id: EVariantArticleKit.SEPARATE_KIT,
            title: state.t('pages.promo.form.fields.variant_article_kit.values.separate_kit'),
            checked: false,
          },
          {
            id: EVariantArticleKit.MISSING,
            title: state.t('pages.promo.form.fields.variant_article_kit.values.missing'),
            checked: false,
          },
        ],
      };

    const formDataField = (state.formData[field] ?? []) as Array<SChipsItemInterface>;

    const mapElement: Array<SChipsItemInterface> = listMap[field] ?? [];

    return formDataField.length > 0 ? formDataField : mapElement;
  }

  /* private getDiscountPlaceholder(state: IState): string {
    const discountInRubChips = state.formData.variantAmountDiscount.find(
      (item: SChipsItemInterface) => item.id === EVariantAmountDiscount.IN_RUB,
    );

    return discountInRubChips?.checked
      ? state.t('pages.promo.form.fields.amount_discount.placeholder.rub')
      : state.t('pages.promo.form.fields.amount_discount.placeholder.percent');
  } */

  private getDiscountMaskOption(state: IState): SMaskedInputMaskInterface {
    const discountInRubChips = state.formData.variantAmountDiscount.find(
      (item: SChipsItemInterface) => item.id === EVariantAmountDiscount.IN_RUB,
    );
    const isDiscountInRub = discountInRubChips?.checked ?? false;

    const mask: SMaskedInputMaskInterface = {
      mask: 'NumberConstructor',
      scale: 6,
      radix: ',',
      mapToRadix: ['.', ','],
      max: 99.999999,
      min: 1,
    };

    if (isDiscountInRub) {
      mask.scale = 2;
      mask.thousandsSeparator = ' ';
      mask.max = 999999.99;
    }

    return mask;
  }

  private getDocumentOptions(state: IState) {
    const maxFileSize = 100;
    const maxFilesSize = 100;

    const fileErrorMessages: FileErrorMessagesType = {
      [FileErrorTypeEnum.LOAD]: state.t(EValidationMessageTokens.FILE_LOAD),
      [FileErrorTypeEnum.MAX_SIZE]: state.t(
        EValidationMessageTokens.FILE_MAX_SIZE,
        {
          number: maxFileSize,
        },
      ),
      [FileErrorTypeEnum.MAX_TOTAL_SIZE]: state.t(
        EValidationMessageTokens.FILE_MAX_TOTAL_SIZE,
        {
          number: maxFilesSize,
        },
      ),
      [FileErrorTypeEnum.MIME_TYPE]: state.t(EValidationMessageTokens.FILE_MIME_TYPE),
      [FileErrorTypeEnum.NO_ZERO_SIZE]: state.t(EValidationMessageTokens.FILE_NO_ZERO_SIZE),
      [FileErrorTypeEnum.NAME]: state.t(EValidationMessageTokens.FILE_NAME),
    };

    const mimeTypes = [
      FileMimeTypesEnum.JPG,
      FileMimeTypesEnum.JPEG,
      FileMimeTypesEnum.PNG,
      FileMimeTypesEnum.PDF,
      FileMimeTypesEnum.EPS,
      FileMimeTypesEnum.TIFF,
    ];

    const extensions = [
      FileExtensionsEnum.JPG,
      FileExtensionsEnum.JPEG,
      FileExtensionsEnum.PNG,
      FileExtensionsEnum.PDF,
      FileExtensionsEnum.EPS,
      FileExtensionsEnum.TIFF,
    ];

    const fileUploadOptions = getFileUploadOptions(
      fileErrorMessages,
      {
        variant: SButtonVariantEnum.TERTIARY,
        text: state.t('pages.promo.form.fields.banner.upload_button'),
        size: SizeEnum.M,
      },
      {
        name: IconNameEnum.PAPER_CLIP,
        color: ColorEnum.PRIMARY,
      },
      state.t(
        'pages.promo.form.fields.banner.description',
        {
          extensions: extensions.join(', '),
          maxFileSize,
          maxFilesSize,
        },
      ),
      state.t(
        'pages.promo.form.fields.banner.placeholder',
        {
          extensions: extensions.join(', '),
          maxFileSize,
          maxFilesSize,
        },
      ),
      mimeTypes,
      maxFileSize,
      maxFilesSize,
      255,
      true,
    );

    return { ...fileUploadOptions };
  }
}

export default FormConfigHandler;
