import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71ec9575"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "promo-form-page" }
const _hoisted_2 = {
  key: 0,
  class: "promo-form-page__promotion-info"
}
const _hoisted_3 = {
  key: 0,
  class: "promo-form-page__promotion-img-wrapper"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "promo-form-page__promotion-text"
}
const _hoisted_6 = { class: "promo-form-page__product-discount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_SButton = _resolveComponent("SButton")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_SMaskedInput = _resolveComponent("SMaskedInput")!
  const _component_FormComponent = _resolveComponent("FormComponent")!
  const _component_CStatusWindow = _resolveComponent("CStatusWindow")!
  const _component_LayoutWithoutToolbar = _resolveComponent("LayoutWithoutToolbar")!

  return (_openBlock(), _createBlock(_component_LayoutWithoutToolbar, {
    title: _ctx.state.localisation.title,
    width: "md"
  }, {
    "control-left": _withCtx(() => [
      (!_ctx.state.isFormSent)
        ? (_openBlock(), _createBlock(_component_SButton, {
            key: 0,
            text: _ctx.buttonCancel.text,
            variant: _ctx.buttonCancel.variant,
            onClick: _ctx.buttonCancel.handler
          }, {
            "icon-before": _withCtx(() => [
              _createVNode(_component_SIcon, {
                color: _ctx.buttonCancel.icon.color,
                name: _ctx.buttonCancel.icon.name
              }, null, 8, ["color", "name"])
            ]),
            _: 1
          }, 8, ["text", "variant", "onClick"]))
        : (_openBlock(), _createBlock(_component_SButton, {
            key: 1,
            text: _ctx.buttonBack.text,
            variant: _ctx.buttonBack.variant,
            onClick: _ctx.buttonBack.handler
          }, {
            "icon-before": _withCtx(() => [
              _createVNode(_component_SIcon, {
                color: _ctx.buttonBack.icon.color,
                name: _ctx.buttonBack.icon.name
              }, null, 8, ["color", "name"])
            ]),
            _: 1
          }, 8, ["text", "variant", "onClick"]))
    ]),
    "control-right": _withCtx(() => [
      (!_ctx.state.isFormSent)
        ? (_openBlock(), _createBlock(_component_SButton, {
            key: 0,
            size: _ctx.buttonSave.size,
            text: _ctx.buttonSave.text,
            variant: _ctx.buttonSave.variant,
            onClick: _ctx.buttonSave.handler
          }, null, 8, ["size", "text", "variant", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.state.isFormSent)
          ? (_openBlock(), _createBlock(_component_FormComponent, {
              key: 0,
              "form-config": _ctx.state.formConfig,
              "form-data": _ctx.state.formData,
              onStringFieldBlur: _ctx.formFieldHandler.handleStringBlur,
              onTextInputListAdd: _ctx.formFieldHandler.handleTextInputListAddField,
              onTextInputListDelete: _ctx.formFieldHandler.handleTextInputListDeleteField,
              onTextInputListInput: _ctx.formFieldHandler.handleTextInputListInput,
              onDateInput: _ctx.formFieldHandler.handleDateInput,
              onChipsGroupChange: _ctx.formFieldHandler.handleChipsGroupChange,
              onFileUploadInput: _ctx.formFieldHandler.handleFileUploadInput,
              onDeleteFile: _ctx.formFieldHandler.handleDeleteFile,
              onDownloadFile: _ctx.formFieldHandler.handleDownloadFile
            }, _createSlots({
              "custom-info-block": _withCtx(({ field }) => [
                (field.fieldName === 'promotionInfo' && _ctx.promotionInfo)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_ctx.promotionInfo.img)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("img", {
                              src: _ctx.promotionInfo.img,
                              alt: "",
                              class: "promo-form-page__promotion-img"
                            }, null, 8, _hoisted_4)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.promotionInfo.text)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_STextElement, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.promotionInfo.text), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, [
              (_ctx.isPersonalDiscount)
                ? {
                    name: "input-list-item-after-input",
                    fn: _withCtx((slotProps) => [
                      _createElementVNode("div", _hoisted_6, [
                        (slotProps.index === 0)
                          ? (_openBlock(), _createBlock(_component_STextElement, {
                              key: 0,
                              class: "promo-form-page__product-discount-label",
                              color: "stroke"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('pages.promo.form.fields.amount_discount.label')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_SMaskedInput, {
                          "mask-options": _ctx.discountMaskOption,
                          "model-value": _ctx.getDiscountValue(slotProps.item),
                          class: "promo-form-page__product-discount-input",
                          onBlur: (event, item) => _ctx.formFieldHandler.handlePersonalDiscountBlur(event, item, slotProps.item)
                        }, null, 8, ["mask-options", "model-value", "onBlur"])
                      ])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["form-config", "form-data", "onStringFieldBlur", "onTextInputListAdd", "onTextInputListDelete", "onTextInputListInput", "onDateInput", "onChipsGroupChange", "onFileUploadInput", "onDeleteFile", "onDownloadFile"]))
          : (_openBlock(), _createBlock(_component_CStatusWindow, {
              key: 1,
              icon: _ctx.statusWindow.icon,
              subtitle: _ctx.statusWindow.subtitle,
              title: _ctx.statusWindow.title
            }, null, 8, ["icon", "subtitle", "title"]))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}