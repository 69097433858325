import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3752b664"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "privacy-policy" }
const _hoisted_2 = { class: "privacy-policy__field" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_SButton = _resolveComponent("SButton")!
  const _component_FormComponent = _resolveComponent("FormComponent")!
  const _component_SCheckbox = _resolveComponent("SCheckbox")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_LayoutWithoutToolbar = _resolveComponent("LayoutWithoutToolbar")!

  return (_openBlock(), _createBlock(_component_LayoutWithoutToolbar, { title: _ctx.pageTitle }, {
    "control-left": _withCtx(() => [
      _createVNode(_component_SButton, {
        text: _ctx.buttonTexts.cancel,
        variant: "transparent",
        onClick: _ctx.handleCancelClick
      }, {
        "icon-before": _withCtx(() => [
          _createVNode(_component_SIcon, {
            color: "primary",
            name: "cross"
          })
        ]),
        _: 1
      }, 8, ["text", "onClick"])
    ]),
    "control-right": _withCtx(() => [
      _createVNode(_component_SButton, {
        text: _ctx.buttonTexts.send,
        disabled: _ctx.isSendDisabled,
        onClick: _ctx.handleSend
      }, null, 8, ["text", "disabled", "onClick"])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_FormComponent, {
        "form-config": _ctx.state.formConfig,
        "form-data": _ctx.state.formData,
        onStringFieldBlur: _ctx.formFieldHandler.handleStringBlur,
        onMultipleSelectInput: _ctx.formFieldHandler.handleMultipleSelectInput,
        onChipsGroupChange: _ctx.formFieldHandler.handleChipsGroupChange,
        onFileUploadInput: _ctx.formFieldHandler.handleFileUploadInput,
        onDeleteFile: _ctx.formFieldHandler.handleDeleteFile,
        onDownloadFile: _ctx.formFieldHandler.handleDownloadFile
      }, null, 8, ["form-config", "form-data", "onStringFieldBlur", "onMultipleSelectInput", "onChipsGroupChange", "onFileUploadInput", "onDeleteFile", "onDownloadFile"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SCheckbox, {
            class: "privacy-policy__checkbox",
            modelValue: _ctx.state.isPrivacyPolicyChecked,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.isPrivacyPolicyChecked) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_STextElement, null, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.privacyPolicy.label
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_STextElement, {
          variant: "small",
          color: "stroke"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.privacyPolicy.comment), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}