import { App } from '@vue/runtime-core';
// @TODO отвязаться от utilsService
import utilsService from '@/app/Service/UtilsService/index';

// eslint-disable-next-line import/extensions,@typescript-eslint/ban-ts-comment
// @ts-ignore
import clickOutside from './lib/Directive/clickOutside';

import defaultConfig from './defaultConfig';

import DesignSystem from './lib/DesignSystem';

import CChipsGroupModeEnum from './lib/Enum/Components/Complex/CChipsGroupModeEnum';
import CDropdownMenuItemTypeEnum from './lib/Enum/Components/Complex/CDropdownMenu/CDropdownMenuItemTypeEnum';
import CDropdownMenuPositionEnum from './lib/Enum/Components/Complex/CDropdownMenu/CDropdownMenuPositionEnum';
import ChipsVariantEnum from './lib/Enum/Components/Simple/UI/ChipsVariantEnum';
import ColorEnum from './lib/Enum/ColorEnum';
import FileErrorTypeEnum from './lib/Enum/Components/Complex/CFileUpload/FileErrorTypeEnum';
import FileExtensionsEnum from './lib/Enum/Components/Complex/CFileUpload/FileExtensionsEnum';
import FileMimeTypesEnum from './lib/Enum/Components/Complex/CFileUpload/FileMimeTypesEnum';
import FileStatusEnum from './lib/Enum/Components/Complex/CFileUpload/FileStatusEnum';
import IconNameEnum from './lib/Enum/Components/Simple/Icon/IconNameEnum';
import SButtonVariantEnum from './lib/Enum/Components/Simple/UI/SButtonVariantEnum';
import SizeEnum from './lib/Enum/SizeEnum';
import StateTypeEnum from './lib/Enum/StateTypeEnum';

import CDropdownMenuItemEmailInterface from './lib/Model/Components/Complex/CDropdownMenu/CDropdownMenuItemEmailInterface';
import CDropdownMenuItemLinkInterface from './lib/Model/Components/Complex/CDropdownMenu/CDropdownMenuItemLinkInterface';
import CDropdownMenuItemTextInterface from './lib/Model/Components/Complex/CDropdownMenu/CDropdownMenuItemTextInterface';
import CDropdownMenuItemType from './lib/Model/Components/Complex/CDropdownMenu/CDropdownMenuItemType';
import CFileUploadOptionsInterface from './lib/Model/Components/Complex/CFileUpload/CFileUploadOptionsInterface';
import CTextInputListItemInterface from './lib/Model/Components/Complex/CTextInputList/CTextInputListItemInterface';
import CTextInputListItemStatusInterface from './lib/Model/Components/Complex/CTextInputList/CTextInputListItemStatusInterface';
import FileErrorMessagesType from './lib/Model/Components/Complex/CFileUpload/FileErrorMessagesType';
import FileInterface from './lib/Model/Components/Complex/CFileUpload/FileInterface';
import PluginOptionsInterface from './lib/PluginOptionsInterface';
import SButtonPropsInterface from './lib/Model/Components/Simple/UI/SButtonPropsInterface';
import SChipsItemInterface from './lib/Model/Components/Complex/SChipsItemInterface';
import SChipsPropsInterface from './lib/Model/Components/Simple/UI/SChipsPropsInterface';
import SIconPropsInterface from './lib/Model/Components/Simple/Icon/SIconPropsInterface';
import SidebarMenuItemInterface from './lib/Model/Components/Complex/SideBar/SidebarMenuItemInterface';
import SidebarMenuMainItemInterface from './lib/Model/Components/Complex/SideBar/SidebarMenuMainItemInterface';
import SLinkConfigInterface from './lib/Model/Components/Simple/Text/SLinkConfigInterface';
import SMaskedInputMaskInterface from './lib/Model/Components/Simple/UI/SMaskedInputMaskInterface';
import UserMenuItemInterface from './lib/Model/Components/Complex/TopBar/UserMenuItemInterface';

import CCard from './Components/Complex/CCard/CCard.vue';
import CChipsGroup from './Components/Complex/CChipsGroup.vue';
import CDropdownMenu from './Components/Complex/CDropdownMenu.vue';
import CFileList from './Components/Complex/CFileUpload/CFileList.vue';
import CFileUploadArea from './Components/Complex/CFileUpload/CFileUploadArea.vue';
import CInfoBlock from './Components/Complex/CInfoBlock/CInfoBlock.vue';
import CMultipleSelect from './Components/Complex/CMultipleSelect.vue';
import CSideBar from './Components/Complex/CSideBar/CSideBar.vue';
import CStatusWindow from './Components/Complex/CStatusWindow/CStatusWindow.vue';
import CTextInputList from './Components/Complex/CTextInputList/CTextInputList.vue';
import CTopBar from './Components/Complex/CTopBar/CTopBar.vue';
import CUserMenu from './Components/Complex/CTopBar/Module/CUserMenu.vue';
import SButton from './Components/Simple/UI/SButton.vue';
import SCheckbox from './Components/Simple/UI/SCheckbox.vue';
import SChips from './Components/Simple/UI/SChips.vue';
import SDatePicker from './Components/Simple/UI/SDatePicker.vue';
import SFileUploadArea from './Components/Simple/UI/SFileUploadArea.vue';
import SHeader from './Components/Simple/Text/SHeader.vue';
import SIcon from './Components/Simple/Icon/SIcon.vue';
import SLabel from './Components/Simple/Text/SLabel.vue';
import SLink from './Components/Simple/Text/SLink.vue';
import SMaskedInput from './Components/Simple/UI/SMaskedInput.vue';
import SPreloaderComponent from './Components/Simple/SPreloaderComponent.vue';
import STextArea from './Components/Simple/UI/STextArea.vue';
import STextElement from './Components/Simple/Text/STextElement.vue';
import STextInput from './Components/Simple/UI/STextInput.vue';

import getFileUploadOptions from './Components/Complex/CFileUpload/getFileUploadOptions';

const packageName = 'designSystem';

export default {
  install: (
    app: App,
    options?: PluginOptionsInterface,
  ) => {
    const designSystem = new DesignSystem(
      defaultConfig,
      options?.config,
      options?.colorSchemas,
    );
    designSystem.initCSSVariables();

    app.use(utilsService);

    app.config.globalProperties.$designSystem = designSystem;
    app.provide(packageName, designSystem);

    app.directive('click-outside', clickOutside);

    app.component(CCard.name, CCard);
    app.component(CChipsGroup.name, CChipsGroup);
    app.component(CDropdownMenu.name, CDropdownMenu);
    app.component(CFileList.name, CFileList);
    app.component(CFileUploadArea.name, CFileUploadArea);
    app.component(CInfoBlock.name, CInfoBlock);
    app.component(CMultipleSelect.name, CMultipleSelect);
    app.component(CSideBar.name, CSideBar);
    app.component(CStatusWindow.name, CStatusWindow);
    app.component(CTextInputList.name, CTextInputList);
    app.component(CTopBar.name, CTopBar);
    app.component(CUserMenu.name, CUserMenu);
    app.component(SButton.name, SButton);
    app.component(SCheckbox.name, SCheckbox);
    app.component(SChips.name, SChips);
    app.component(SDatePicker.name, SDatePicker);
    app.component(SFileUploadArea.name, SFileUploadArea);
    app.component(SHeader.name, SHeader);
    app.component(SIcon.name, SIcon);
    app.component(SLabel.name, SLabel);
    app.component(SLink.name, SLink);
    app.component(SMaskedInput.name, SMaskedInput);
    app.component(SPreloaderComponent.name, SPreloaderComponent);
    app.component(STextArea.name, STextArea);
    app.component(STextElement.name, STextElement);
    app.component(STextInput.name, STextInput);
  },
};

export {
  packageName,
  CChipsGroupModeEnum,
  CDropdownMenuItemEmailInterface,
  CDropdownMenuItemLinkInterface,
  CDropdownMenuItemTextInterface,
  CDropdownMenuItemType,
  CDropdownMenuItemTypeEnum,
  CDropdownMenuPositionEnum,
  CFileUploadOptionsInterface,
  ChipsVariantEnum,
  ColorEnum,
  CTextInputListItemInterface,
  CTextInputListItemStatusInterface,
  FileErrorMessagesType,
  FileErrorTypeEnum,
  FileExtensionsEnum,
  FileInterface,
  FileMimeTypesEnum,
  FileStatusEnum,
  getFileUploadOptions,
  IconNameEnum,
  PluginOptionsInterface,
  SButtonPropsInterface,
  SButtonVariantEnum,
  SChipsItemInterface,
  SChipsPropsInterface,
  SIconPropsInterface,
  SidebarMenuItemInterface,
  SidebarMenuMainItemInterface,
  SizeEnum,
  SLinkConfigInterface,
  SMaskedInputMaskInterface,
  StateTypeEnum,
  UserMenuItemInterface,
};
